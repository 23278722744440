import {LandingPage} from "../components/LandingPage";
import { Navigation } from "../components/navigation";
import About from "../components/About";
import Features from "../components/Features";
import Rooms from "../components/Rooms";
import { Faq, ImageContainer } from "../components/components";
import Footer from "../components/Footer";

const Main = ({data}) => {
  return (
      <>
         <Navigation/>
         <LandingPage/>
         <About/>
         <Features/>
         <Rooms data={data}/>
         <ImageContainer img={"https://images.unsplash.com/photo-1521783988139-89397d761dce?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8OXw5MjczOTAxfHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60"} text={"Every night deserves to be comfortable"} btn={"Book Now"}/>
         <Faq/>
         <Footer/>
      </>
  );
}

export default Main;


