import './App.css';
import Main from './Page/main';
import {Route, Switch} from 'react-router-dom'
import Details from './Page/Details';

function App() {
  const data = [
    {
      name : "Deluxe Double Room",
      id : "one",
      sleeps : 
        {
          one : "29.00",
          two : "32.00"
        }
      ,
      bed : "1 double bed",
      features : ["Private kitchen", "Flat screen tv", "Free packing"],
      img : [
        "img/first1.jpeg",
        "img/first2.jpg",
        "img/first3.jpg",
        "img/first4.jpg",
        "img/first5.jpg",
        "img/first7.jpg",
            ]
    },
    {
      name : "Single Room with Private Bathroom",
      sleeps : [
        {
          one : "29.00",
          two : "32.00"
        }
      ],
      bed : "1 single bed",
      features : ["private kitchen", "Flat screen tv","Private Bathroom","Garden view"],
      img : [
        "img/second1.jpg",
        "img/second2.jpg",
        "img/second3.jpeg",
        "img/second4.jpg",
        "img/second5.jpg",
        "img/second6.jpg",
      ]
    },
    {
      name : "Double Room with Garden View",
      sleeps : [
        {
          one : "29.00",
          two : "32.00"
        }
      ],
      bed : "1 single bed",
      features : ["Private kitchen", "Flat screen tv","Private Bathroom","Garden view"],
      img : [
        "img/third1.jpeg",
        "img/third2.jpg",
        "img/third3.jpg",
        "img/third5.jpg",
        "img/third6.jpg",
        "img/third7.jpg",
      ]
    }
  
  ]
  
  return (
    <Switch>
          <Route path="/" exact>
            <Main data={data}/>
          </Route>
          <Route path={"/:id"}>
            <Details data={data}/>
          </Route>
          
    </Switch>
  );
}

export default App;


