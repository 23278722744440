import React from 'react'
import { Carousel, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Box = ({color, img, text}) => {
    return (
        <div className='jumbotron text-center' style={{backgroundColor : color}} >
            <img src={img} height={90}/>
            <p style={{color :"white", paddingTop : "10px"}}>{text}</p>
        </div>
    )
}

export default Box

export const Feature = ({img, text, color}) => {
    return(
        <div className='text-center feat'>
            <div className='jumbotron' style={{background : color, padding : "30px"}}>
                <img src={img} height={70} alt="icon" />
            </div>
            <p>{text}</p>
        </div>
    )
}

export const Room = ({title, feature, size, facilities, img, id}) => {
    return(
        <div className='jumbotron'> 
            <div className='row'>
                <div className='col-md-6'>
                    <h2>{title}</h2>
                    <div className='row'>
                        {
                            feature.map(val => (
                                <div  className='col-xs-4 col-md-3' style={{ marginTop : "5px"}}>
                                    <div style={{backgroundColor : "white", textTransform : "capitalize", padding  : "10px",}}>{val}</div>
                                </div>
                            ))
                        }
                    </div>
                    <div style={{paddingTop : "15px", paddingBottom : "10px"}}>
                        SIZE : <span>{size}</span>
                    </div>
                   <div className='row'>
                   <div style={{paddingTop : "20px"}} className='col-md-6'>
                       <p className='font-weight-bold'>Facilities</p>  
                       <ul className='list-unstyled'>
                           { facilities.map(item => (
                               <li><i className='fa fa-check text-success' style={{marginRight : "10px"}}></i>{item}</li>
                           ))}
                       </ul>
                    </div>
                    <div className='col-md-6'>
                        <div></div>
                    </div>
                   </div>
                    <div>
                      <Link to={`/${title}`}>
                        <button className='btn btn-lg' style={{background : "linear-gradient(to right, #8b0505 0%,#ec7171  100%)", padding : "15px",color : "white", marginTop : "20px"}}>
                            Check Out
                        </button>                      
                      </Link>

                    </div>
                </div>
                <div className='col-md-6'>
                <div id={id} class="carousel slide" data-ride="carousel">
                     <div class="carousel-inner">
                        {
                        img.map((value, ndex) => (
                        <div class={`item ${ndex == 0 && "active"}`}>
                        <img src={value} height={"100%"} alt="Los Angeles"/>
                        </div>
                        )) 
                        }
                        </div> 
                   
                </div> 
                    <ol class="carousel-indicators row">
                        {
                            img.map((val, index) => (
                              <div className='col-xs-4 col-md-2'>
                                <img style={{ border : "5px solid white"}} data-target={`#${id}`} src={val} data-slide-to={index} height={60} width={60}/>
                              </div>
                            ))
                        }
                    </ol>
                </div>
            </div>

        </div>
    )
}

export const ImageContainer = ({img, text, btn}) => {
    const styles = {
        overlay : {
            height : "100%",
            width : "100%",
            background : " rgba(37, 44, 70, 0.3);"
        },
        content : {
            marginTop : "100px",
            marginBottom : "100px"
        },
        text : {
            marginBottom : "30px"
        },
        btn : {
            background : "white",
            padding : "20px"
        }
    }
    return(
                <div className="aside">
                <div style={styles.overlay} className=''>
                    <div className="container holder">
                      <Row>
                        <Col md="6">
                        <h1 style={styles.text}>{text}</h1>
                        <button style={styles.btn} className='btn'>{btn}</button>
                        </Col>
                      </Row>
                    </div>
                       
                </div>
            </div>
    )
}


export const Faq = () => {
    return(
        <div className='jumbotron container' style={{marginTop : "100px"}}>
            <h2 style={{fontFamily : "Bahnschrift Light"}}>FAQ About Mcpherson</h2>
            <div class="panel-group" id="accordion">
  <div class="panel panel-default">
    <div class="panel-heading">
      <a data-toggle="collapse"  data-parent="#accordion" href="#collapse1">
      <h4 class="panel-title" style={{padding : "10px", color : "#ec7171"}}>
        
        What type of room can I book at Macpherson Lodge?     </h4>
        </a>
    </div>
    <div id="collapse1" class="panel-collapse collapse in">
      <div class="panel-body">
         Room options at Macpherson Lodge include:
        <ol>
          <li>Double</li>
          <li>Single</li>
        </ol>
      </div>
    </div>
  </div>
  <div class="panel panel-default">
    <div class="panel-heading">
      <a data-toggle="collapse"  data-parent="#accordion" href="#collapse2">
      <h4 class="panel-title" style={{padding : "10px", color : "#ec7171"}} >
        
        How much does it cost to stay at Macpherson Lodge?     </h4>
        </a>
    </div>
    <div id="collapse2" class="panel-collapse collapse">
      <div class="panel-body">The prices at Macpherson Lodge may vary depending on your stay (e.g. dates you select, hotel's policy etc.). See the prices by contacting us.</div>
    </div>
  </div>
  <div class="panel panel-default">
    <div class="panel-heading">
      <a data-toggle="collapse"  data-parent="#accordion" href="#collapse3">
      <h4 class="panel-title" style={{padding : "10px", color : "#ec7171"}}>
        
        What is there to do at Macpherson Lodge?     </h4>
        </a>
    </div>
    <div id="collapse3" class="panel-collapse collapse">
      <div class="panel-body">
      Macpherson Lodge offers the following activities / services (charges may apply):
      <ol>
        <li>Table tennis</li>
      </ol>
      </div>
    </div>
  </div>
  <div class="panel panel-default">
    <div class="panel-heading">
      <a data-toggle="collapse"  data-parent="#accordion" href="#collapse5">
      <h4 class="panel-title" style={{padding : "10px", color : "#ec7171"}}>
        
        Can I park a car at Macpherson Lodge?     </h4>
        </a>
    </div>
    <div id="collapse5" class="panel-collapse collapse">
      <div class="panel-body">The following parking options are available to guests staying at Macpherson Lodge (subject to availability):
      <li>Parking on site</li>
      <li>Private parking</li>
      <li>Free Parking</li>
      </div>
    </div>
  </div>
  <div class="panel panel-default">
    <div class="panel-heading">
      <a data-toggle="collapse"  data-parent="#accordion" href="#collapse4">
      <h4 class="panel-title" style={{padding : "10px", color : "#ec7171"}}>
        
        How can I get to Macpherson Lodge from the nearest airport?     </h4>
        </a>
    </div>
    <div id="collapse4" class="panel-collapse collapse">
      <div class="panel-body">From the nearest airport, you can get to Macpherson Lodge by:
      <li>Car 10min</li>
      <li>Taxi 10min</li>
      </div>
    </div>
  </div>
</div>
        </div>
    )
}