import React from 'react'
import { Feature } from './components'

const Features = () => {
    return (
        <div className="container" >
            <hr/>
            <div className='row'>
                <div className='col-xs-6 col-sm-3'>
                    <Feature img={"img/cheap.png"} text={"Affordable Prices"} color={"linear-gradient(to right, #8b0505 0%,#ec7171  100%)"}/>
                </div>
                <div className='col-xs-6 col-sm-3'>
                    <Feature img={"img/free2.png"} text={"Free Booking"} color={"linear-gradient(to right, #8b0505 0%,#ec7171  100%)"}/>
                </div>
                <div className='col-xs-6 col-sm-3'>
                    <Feature img={"img/easy.png"} text={"Easy booking online"} color={"linear-gradient(to right, #8b0505 0%,#ec7171  100%)"}/>
                </div>
                <div className='col-xs-6 col-sm-3'>
                    <Feature img={"img/support.png"} text={"Customer Support"} color={"linear-gradient(to right, #8b0505 0%,#ec7171  100%)"}/>
                </div>
            </div>
            <hr/>
        </div>
    )
}

export default Features
