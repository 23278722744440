import React from 'react'

const Footer = () => {
    return (
        <div className='text-white' style={{backgroundColor : "#2E2A2A", color : "white", marginTop : "100px"}}>
            <div className='container text-center' style={{paddingTop : "100px", paddingBottom : "100px"}}>
                <h2 style={{paddingBottom : "70px", color : "white"}}>CONTACT US</h2>
                <div className="row">
                    <div className="col-md-4" style={{borderRight : "2px solid #ec7171", padding : "40px 80px"}}>
                        <h4 style={{color : "white"}}>Address Information</h4> <br />
                        <p>110 Javelin Avenue, Castle Vale, Birmingham B35 7LW</p>
                    </div>
                    <div className="col-md-4" style={{borderRight : "2px solid #ec7171", padding : "40px 80px"}}>
                        <h4 style={{color : "white"}}>Contact Us</h4> <br />
                        <a href='tel:+44 (0) 1215729718'><p>+44 (0) 1215729718</p> </a>
                        <a href='mailto:Info@bggm.co.uk'><p>Info@bggm.co.uk</p> </a>
                    </div>
                    <div className="col-md-4" style={{padding : "40px 80px"}}>
                        <h4 style={{color : "white"}}>Desk Hours</h4> <br />
                        <p>MON - SUN</p>
                        <p>8am -10pm</p>
                    </div>
                </div>

            </div>
            <div className='text-center' style={{background : "#6666", padding : "20px"}}>
                <h4 style={{color : "white"}}>Powered By BGGM Ltd.</h4> 
            </div>
        </div>
    )
}

export default Footer
