import React from 'react'

const About = () => {
    return (
        <div className='container' id="about" style={{paddingTop : "100px", paddingBottom : "100px"}}>
            <div className='jumbotron'>
                <div className='row'>
                    <div className='col-md-6 ' style={{paddingTop : "20px"}}>
                         <img className='img-responsive' src="https://a0.muscache.com/im/pictures/prohost-api/Hosting-53729896/original/a9b7fb37-e5f9-4b3b-9532-9af826daa989.jpeg?im_w=720" alt="about"/>
                    </div>
                    <div className='col-md-6 ' style={{paddingTop : "20px"}}>
                         <div className='container'>
                             <h2 style={{fontFamily : 'Bahnschrift Light'}}>Macpherson Lounge</h2>
                             <p style={{paddingTop : "20px"}}>Beautfiully furnished guest house rated 4 star with a large kitchen and garden. It has many original features: spacious rooms & high ceilings. Free wifi internet and private parking are available with deluxe rooms and stylish decor.</p>
                             <p style={{paddingTop : "20px"}}>The neighbourhood is suitable for families and professional guests. Located in a quiet residential area close to Castle Vale Retail Park, Jaguar Land Rover and Brmingham Fort.</p>
                         </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
