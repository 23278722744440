import Box from "./components"

export const LandingPage = (props) => {
    return (
      <header id='header' style={{marginTop : "-20px"}}>
        <div className='intro'>
          <div className='overlay'>
              <div className='row'>
                <div className='col-md-8 col-md-offset-2 intro-text'>
                  <h1>
                    Luxury Rooms As Low As <b>&#163;37</b> 
                    <span></span>
                  </h1>
                  <p>Have your next getaway at Macpherson Lodge, Castle Vale.</p>
                  <a
                    href='#about'
                    className='btn btn-warning  btn-lg'
                  >
                    Learn More
                  </a>{' '}
                </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row" style={{marginTop : "-100px"}}>
            <div className="col-md-4 box">
              <Box img={"img/card.png"} color={"#800000"} text={"Free Booking"}/>
            </div>
            <div className="col-md-4 box">
              <Box img={"img/free.png"} color={"#800000"} text={"Free Cancellation"}/>
            </div>
            <div className="col-md-4 box">
              <Box img={"img/parking.png"} color={"#800000"} text={"Free Parking"}/>
            </div>
          </div>
        </div>
      </header>
    )
  }
  