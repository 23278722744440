import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Navigation } from '../components/navigation'
import Footer from '../components/Footer'

const Details = ({data}) => {
    const [info, setInfo] = useState(false)
    useEffect(() => {
        const path = window.location.pathname;
        const uri = decodeURIComponent(path).substring(1)
        const info = data.find(({name}) => (name == uri));
        setInfo(info)
    }
    , [data]);

    console.log(info)
    const facilities = ["Kitchen Ware", "Free Toiletries", "Refrigerator", "Washing machine", "Bath and shower", "Hair dryer"]

    return (
        <div style={{background : "#F0F0F0"}}>
            <Navigation/>
            {
                info && (
            <Container style={{background : "white", paddingTop : "100px", marginTop : "40px"}}>
                <Row>
                    <Col md="6">
                    <div id={info.id} class="carousel slide" data-ride="carousel">
                     <div class="carousel-inner">
                        {
                        info.img.map((value, ndex) => (
                        <div style={{height : "500px"}} class={`item ${ndex == 0 && "active"}`}>
                        <img src={value} className='img-thumbnail' alt="Los Angeles"/>
                        </div>
                        )) 
                        }
                        </div> 
                   
                </div> 
                    <ol class="carousel-indicators">
                        {
                            info.img.map((val, index) => (
                                <img style={{marginRight : "5px", border : "5px solid white"}} data-target={`#${info.id}`} src={val} data-slide-to={index} height={50}/>
                            ))
                        }
                    </ol>
                    </Col>
                    <Col md="6">
                         <h2>{info.name}</h2>
                         <hr />
                         <div className='row'>
                            {
                                info.features.map(val => (
                                    <div  className='col-xs-4'>
                                        <div style={{backgroundColor : "#6666", padding  : "10px",}}>{val}</div>
                                    </div>
                                ))
                            }
                         </div>
                        
                        <div style={{padding : "10px 0px"}}>
                            <h3><i className="fa fa-bed"></i> {info.bed}</h3>
                        </div>
                            <div className='row'>
                                <div className='col-sm-6 border-right' style={{borderRight : "1px solid black", padding :"20px"}}>
                                 <h4>Facilities</h4>
                                 { facilities.map(item => (
                                        <li className='list-unstyled'><i className='fa fa-check text-success' style={{marginRight : "10px"}}></i>{item}</li>
                                  ))}
                                </div>
                                <div className='col-sm-6'>
                                        <table className='table table-striped text-center'>
                                            <thead>
                                                <th style={{padding : "10px"}}><h4>Sleeps</h4> </th>
                                                <th className='text-center' style={{padding : "10px"}}><h4>Price</h4></th>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{padding : "15px"}}><b>One</b></td>
                                                    <td style={{padding : "15px"}}><b>$28</b> <br /> + 12$ taxes included.</td>
                                                </tr>
                                                <tr>
                                                    <td style={{padding : "15px"}}><b>Two</b></td>
                                                    <td style={{padding : "15px"}}><b>$38</b> <br /> + 12$ taxes included.</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                            </div>
                            <div style={{padding : "20px 0px"}}>
                                <a href="tel:+44 (0) 1215729718"><button className='btn btn-info btn-lg' style={{width :"45%"}}><i className="fa fa-mobile"></i> Call To Book</button></a> <a href="mailto:info@bggm.co.uk"><button className='btn btn-primary pull-right btn-lg' style={{width :"45%"}}><i className="fa fa-envelope"></i> Mail To Book</button></a>
                            </div>
                            <div>
                                <h4>Property information</h4>
                                <p>
                                Beautfiully furnished guest house rated 4 star with a large kitchen and garden. It has many original features: spacious rooms & high ceilings. Free wifi internet and private parking are available with deluxe rooms and stylish decor. Two toilets with a bath, a shower and a central lounge area which is tastefully furnished is available for guests to enjoy.
                                </p>
                            </div>
                            <div>
                                <h4>
                                Neighbourhood information
                                </h4>
                                <p>
                                The neighbourhood is suitable for families and professional guests. It is located in a quiet residential area close to Castle Vale Retail Park, Jaguar Land Rover and Birmingham Fort. Perfect for night life with Vue Cinema Birmingham Star City close by.Castle Vale is a housing estate located between Erdington, Minworth and Castle Bromwich.
                                </p>
                            </div>
                    </Col>
                </Row>                      

            </Container>                    
                )
            }

            <Footer/>
        </div>
    )
}

export default Details
